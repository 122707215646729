import React, {FC} from 'react';
import {Wrapper, FooterHeading, FooterBlock, FooterText, SocialWrapper, SocialLink} from "./styles";
import {ReactComponent as VkIcon} from "../../assets/icons/icons8-vk.svg";
import {ReactComponent as YaIcon} from "../../assets/icons/icons8-yandex.svg";

const About: FC = () => {
    return (
        <Wrapper>
            <FooterBlock>
                <FooterHeading>IGRUNKA GAMES</FooterHeading>
                <FooterText>&copy; 2023 Все права защищены</FooterText>
            </FooterBlock>
            <FooterBlock>
                <FooterHeading>Контакты</FooterHeading>
                <FooterText>Oбщество с ограниченной ответственностью "Игрунка Геймс"</FooterText>
                <FooterText>Адрес: 194358, Россия, Санкт-Петербург, вн.тер.г. поселок Парголово, ул. Федора Абрамова, д. 4, литера А, кв. 711</FooterText>
                <FooterText>ОГРН: 1237800135759</FooterText>
                <FooterText>ИНН: 7802947367</FooterText>
                <FooterText>КПП: 780201001</FooterText>
                <FooterText>igrunkagames@yandex.ru</FooterText>
                <FooterText>+7 (921) 744-20-76</FooterText>
            </FooterBlock>
            <FooterBlock>
                <FooterHeading/>
                <SocialWrapper>
                    <SocialLink href="https://vk.com/igrunkastudio" target="_blank">
                        <VkIcon/>
                    </SocialLink>
                    <SocialLink href="https://yandex.ru/games/developer?name=Igrunka%20Games" target="_blank">
                        <YaIcon/>
                    </SocialLink>
                </SocialWrapper>
            </FooterBlock>
        </Wrapper>
    );
}

export default About;