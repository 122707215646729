import styled from "styled-components";
import Cover from "../../assets/images/cover1.jpg";

export const Wrapper = styled.div`
  margin: 20px 0;
  padding: 0 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const ContentHeader = styled.div`
  font-size: 36px;
  font-weight: 700;
  color: #542e86;
  margin-bottom: 40px;
  position: relative;

  &:after {
    margin-left: -10px;
    bottom: -12px;
    left: 50%;
    position: absolute;
    content: '';
    width: 32px;
    height: 5px;
    background-color: #1d2189;
  }
`;

export const GamesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 50px;
  margin: 50px 100px;
`;

export const SocialWrapper = styled.div`
  max-width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: all ease-in-out .4s;
`;

export const SocialWrapperAlt = styled.div`
  max-width: 100%;
  display: flex;
  align-items: center;
  margin-left: -17px;
  transition: all ease-in-out .4s;
`;

export const SocialLink = styled.a`
  margin: 10px;
  color: #ababab;
  transition: all ease-in-out .3s;

  &:hover {
    color: #e5e5e5;
  }
`;

export const SocialLinkAlt = styled.a`
  margin: 0 10px;
  color: #a088b0;
  transition: all ease-in-out .3s;

  &:hover {
    color: #6C43AB;
  }
`;

export const GameCard = styled.div`
  min-width: 400px;
  height: 250px;
  background-image: url(${Cover});
  border-radius: 20px;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  transition: all ease-in-out .4s;
  
  &:hover {
    background-size: 110% 110%;
    background-blend-mode: darken;
    background-color: rgba(0,0,0, 0.6);
    
    & ${SocialWrapper} {
      opacity: 1;
    }
  }
`;

export const GameCardMock = styled.div`
  min-width: 400px;
  height: 250px;
  border-radius: 20px;
  border: #a088b0 dashed 3px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const GameCardMockText = styled.div`
  font-size: 36px;
  font-weight: 500;
  color: #a088b0;
`;

export const GameCardWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 50px;
  align-items: center;
`;

export const GameCardText = styled.div`
  font-size: 18px;
  font-weight: 700;
  color: #2a2a2a;
  margin: 20px 0 10px 0;
`;

export const GameCardDescriptionWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    text-align: left;
  flex-shrink: 1;
`;

export const GameCardDescription = styled.div`
  font-size: 16px;
  font-weight: 500;
  color: #2a2a2a;
`;