import React from 'react';
import './App.css';
import MainScreen from "./components/MainScreen";
import Projects from "./components/Projects";
import Footer from "./components/Footer";
import About from "./components/About";
import Contacts from "./components/Contacts";

function App() {
  return (
    <div className="App">
        <MainScreen/>
        <About/>
        <Projects/>
        <Contacts/>
        <Footer/>
    </div>
  );
}

export default App;
