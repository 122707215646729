import React, {FC, MouseEventHandler} from 'react';
import {Background, Logo, MenuWrapper, MenuLink, SocialLink} from './styles';
import {ReactComponent as VkIcon} from '../../../../assets/icons/icons8-vk.svg';
import {ReactComponent as YaIcon} from '../../../../assets/icons/icons8-yandex.svg';

const Header: FC = () => {

    const handleGamesClick = (e: { preventDefault: Function }) => {
        e?.preventDefault();
        const section = document.querySelector( '#games' );
        section?.scrollIntoView( { behavior: 'smooth', block: 'start' } );
    }

    return (
        <Background>
            <Logo>
                IGRUNKA
            </Logo>
            <MenuWrapper>
                <MenuLink onClick={handleGamesClick} href="#games">Наши игры</MenuLink>
                <SocialLink href="https://vk.com/igrunkastudio" target="_blank">
                    <VkIcon/>
                </SocialLink>
                <SocialLink href="https://yandex.ru/games/developer?name=Igrunka%20Games" target="_blank">
                    <YaIcon/>
                </SocialLink>
            </MenuWrapper>
        </Background>
    );
}

export default Header;