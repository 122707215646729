import React, {FC} from 'react';
import Header from "./components/Header";
import {Wrapper, Content, ContentHeader, ContentDescription, Art, ArtWrapper, ContentText} from "./styles";

const MainScreen: FC = () => {
    return (
        <Wrapper>
            <Header/>
            <Content>
                <ContentHeader>ИГРУНКА ГЕЙМС</ContentHeader>
                <ContentDescription>Студия разработки игр</ContentDescription>
                <ContentText>
                    Мы молодая инди студия игровой разработки из Санкт-Петербурга, основанная в 2023 году. Наша команда профессионалов с
                    многолетним опытом работы в геймдев индустрии стремится создавать увлекательные игры, которые приносят радость сотням тысяч игроков.
                </ContentText>
            </Content>
            <ArtWrapper>
                <Art/>
            </ArtWrapper>
        </Wrapper>
    );
}

export default MainScreen;