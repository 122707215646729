import React, {FC} from 'react';
import {
    Wrapper,
    ContentHeader,
    GamesWrapper,
    GameCard,
    GameCardWrapper,
    SocialWrapper,
    SocialLink,
    GameCardText,
    SocialLinkAlt,
    SocialWrapperAlt,
    GameCardMock,
    GameCardMockText,
    GameCardDescriptionWrapper,
    GameCardDescription
} from "./styles";
import {ReactComponent as VkIcon} from '../../assets/icons/icons8-vk.svg';
import {ReactComponent as YaIcon} from '../../assets/icons/icons8-yandex.svg';

const Projects: FC = () => {
    return (
        <Wrapper>
            <ContentHeader id="games">НАШИ ИГРЫ</ContentHeader>

            <GamesWrapper>
                <GameCardWrapper>
                    <GameCard>
                        <SocialWrapper>
                            <SocialLink href="https://vk.com/app51746892" target="_blank">
                                <VkIcon/>
                            </SocialLink>
                            <SocialLink href="https://yandex.ru/games/app/254839?lang=ru" target="_blank">
                                <YaIcon/>
                            </SocialLink>
                        </SocialWrapper>
                    </GameCard>
                    <GameCardDescriptionWrapper>
                        <GameCardText>
                            Маджонг Классика
                        </GameCardText>
                        <GameCardDescription>
                            Классический пасьянс маджонг. Наша команда смогла реализовать уникальный алгоритм генерации уровней -
                            рандомная выкладка с гарантированным решением уровня. Эта уникальная разработка позволяет игрокам неограниченное число
                            раз проходить любимые поля с интересом.
                            В игру играет уже более 80 000 игроков на платформах Яндекс.Игры и В Контакте. Сейчас готовится версия для
                            социальной сети Одноклассники.
                        </GameCardDescription>
                        <SocialWrapperAlt>
                            <SocialLinkAlt href="https://vk.com/app51746892" target="_blank">
                                <VkIcon/>
                            </SocialLinkAlt>
                            <SocialLinkAlt href="https://yandex.ru/games/app/254839?lang=ru" target="_blank">
                                <YaIcon/>
                            </SocialLinkAlt>
                        </SocialWrapperAlt>
                    </GameCardDescriptionWrapper>
                </GameCardWrapper>
                <GameCardWrapper>
                    <GameCardMock>
                        <GameCardMockText>
                            СКОРО
                        </GameCardMockText>
                    </GameCardMock>
                    <GameCardText>
                        Археология Кликер. Выходит в 2024 году
                    </GameCardText>
                </GameCardWrapper>
            </GamesWrapper>
        </Wrapper>
    );
}

export default Projects;