import React, {FC} from 'react';
import {Wrapper, ContentHeader, ContentDescription} from "./styles";

const About: FC = () => {
    return (
        <Wrapper>
            <ContentHeader>ТЕХНОЛОГИИ</ContentHeader>

            <ContentDescription>
                Наша студия специализируется на разработке браузерных HTML5 игр. Для достижения высокого качества и производительности наших
                продуктов мы используем современный и гибкий стек технологий. В клиентской части приложения мы используем игровой движок с открытым
                исходным кодом Cocos Creator для интеграции
                интерфейсов и игровой логики на языке TypeScript, внутренние инструменты для разработки написаны на JavaScript + Node.js. В серверной
                части игр мы используем TypeScript + Node.js, а данные храним в MySQL. Все наши игры использут для рендеринга WebGL API, позволяющий
                добиться высокой производительности при отрисовке игры в среде браузера. Помимо этого в наших продуктах используются React, Webpack,
                HTML, CSS и прочие.
            </ContentDescription>
        </Wrapper>
    );
}

export default About;