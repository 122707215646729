import styled from 'styled-components';

export const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  align-items: center;
  background-color: transparent;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 5px 100px;
`;

export const Logo = styled.p`
  font-size: 36px;
  font-weight: 900;
  color: #6C43AB;
`;

export const MenuWrapper = styled.div`
  font-size: 14px;
  font-weight: 300;
  color: #e5e5e5;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const MenuLink = styled.a`
  font-size: 20px;
  font-weight: 300;
  color: #2a2a2a;
  text-decoration: none;
  padding: 2px 10px;
`;

export const SocialLink = styled.a`
  margin-left: 40px;
  color: #a088b0;
  text-decoration: none;
  transition: all ease-in-out .3s;

  &:hover {
    color: #6C43AB;
  }
`;