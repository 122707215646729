import styled from "styled-components";

export const Wrapper = styled.div`
  margin-top: 150px;
  padding: 40px 100px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  background-color: #371c64;
`;

export const FooterBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 500px;
`;

export const FooterHeading = styled.div`
  font-size: 18px;
  font-weight: 700;
  color: #e5e5e5;
  margin-bottom: 20px;
`;

export const FooterText = styled.div`
  font-size: 14px;
  font-weight: 300;
  color: #e5e5e5;
  text-align: left;
`;

export const SocialLink = styled.a`
  color: #c0c0c0;
  text-decoration: none;
  transition: all ease-in-out .3s;
  width: 40px;
  height: 40px;

  &:hover {
    color: #e5e5e5;
  }
  
  & svg {
    max-width: 100%;
    max-height: 100%;
  }
`;

export const SocialWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
`;

