import React, {FC} from 'react';
import {Wrapper, ContentHeader, ContentDescription} from "./styles";

const Contacts: FC = () => {
    return (
        <Wrapper>
            <ContentHeader>Контакты</ContentHeader>

            <ContentDescription>
                Oбщество с ограниченной ответственностью "Игрунка Геймс"
            </ContentDescription>
            <ContentDescription>
                Адрес: 194358, Россия, Санкт-Петербург, вн.тер.г. поселок Парголово, ул. Федора Абрамова, д. 4, литера А, кв. 711
            </ContentDescription>
            <ContentDescription>
                ОГРН: 1237800135759
            </ContentDescription>
            <ContentDescription>
                ИНН: 7802947367
            </ContentDescription>
            <ContentDescription>
                КПП: 780201001
            </ContentDescription>
            <ContentDescription>
                Эл. почта: igrunkagames@yandex.ru
            </ContentDescription>
            <ContentDescription>
                Телефон: +7 (921) 744-20-76
            </ContentDescription>
        </Wrapper>
    );
}

export default Contacts;