import styled from "styled-components";
import {ReactComponent as MainArt} from '../../assets/icons/art-main.svg';

export const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Content = styled.div`
  margin-top: -80px;
  text-align: left;
  padding: 20px 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 30px;
`;

export const ContentHeader = styled.div`
  font-size: 67px;
  font-weight: 900;
  //color: #e5e5e5;
  color: #542e86;
`;

export const ContentDescription = styled.div`
  font-size: 30px;
  font-weight: 500;
  color: #9763c2
`;

export const ContentText = styled.div`
  font-size: 24px;
  font-weight: 300;
  color: #2a2a2a;
`;

export const Art = styled(MainArt)`
  max-height: 125%;
  margin-right: -4px;
  margin-top: -80px;
`;
export const ArtWrapper = styled.div`
  max-height: 100% ;
`;