import styled from "styled-components";

export const Wrapper = styled.div`
  margin: 60px 0 150px 0;
  padding: 0 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const ContentHeader = styled.div`
  font-size: 36px;
  font-weight: 700;
  color: #542e86;
  margin-bottom: 70px;
  position: relative;

  &:after {
    margin-left: -10px;
    bottom: -12px;
    left: 50%;
    position: absolute;
    content: '';
    width: 32px;
    height: 5px;
    background-color: #1d2189;
  }
`;

export const ContentDescription = styled.div`
  max-width: 790px;
  font-size: 20px;
  font-weight: 300;
  color: #2a2a2a;
`;